module.exports = [{
      plugin: require('/home/runner/work/portfolio-gatsby/portfolio-gatsby/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Takashi Narikawa - @fukubaka0825","short_name":"nari","start_url":"/","background_color":"#08033d","theme_color":"#08033d","display":"minimal-ui","icon":"src/assets/images/nari.png"},
    },{
      plugin: require('/home/runner/work/portfolio-gatsby/portfolio-gatsby/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/portfolio-gatsby/portfolio-gatsby/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":500},
    },{
      plugin: require('/home/runner/work/portfolio-gatsby/portfolio-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
